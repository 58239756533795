<template>
  <section-description-photo
    title=""
    :description="documentToHtmlString(translations.aboutDescriptionPhotoDescription)"
    :link="true"
    :link-name="translations.aboutDescriptionPhotoLinkName"
    :image="getImgUrl(translations.aboutDescriptionPhoto)"
  />
</template>

<script>
  import SectionDescriptionPhoto from '@/views/sections/reusable/DescriptionPhoto'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_ABOUT } from '@/views/about/Index.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import getImgUrl from '@/utils/getImgUrl.js'

  export default {
    name: 'SectionAboutDescriptionPhoto',
    components: {
      SectionDescriptionPhoto,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ABOUT, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
      getImgUrl,
    },
  }
</script>
